<!-- =========================================================================================
    File Name: CardBasic.vue
    Description: Basic Cards
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="demo-basic-card">
    <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-avatar size="100px" :src="gallery_photo" />
      </vs-col>
    </vs-row>

    <br />
    <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h2>{{ gallery_name }}</h2>
      </vs-col>
    </vs-row>

    <br /><br /><br />

    <div class="vx-row">
      <div v-for="item in links" :key="item.name" class="vx-col w-full sm:w-1/5 lg:w-1/5 mb-base">
        <vx-card @click="OpenLink(item.hobjects[0].pid)" class="overlay-card overflow-hidden">
          <template v-if="item.hobjects[0].original_image" slot="no-body">
            <img :src="item.hobjects[0].original_image" alt="user-profile-cover" class="responsive" />
            <div class="card-overlay text-white flex flex-col justify-between">
              <h4 class="text-white mb-4">{{ item.hobjects[0].name }}</h4>
              <!-- <p>Cake sesame snaps cupcake gingerbread danish I love gingerbread.</p> -->
            </div>
          </template>
        </vx-card>

        <!-- <vx-card>
                    <div slot="no-body">
                        <img :src="item.hobjects[0].original_image" alt="content-img" class="responsive card-img-top">
                    </div>
                    <h5 class="mb-2">{{ item.hobjects[0].name }}</h5>
                    <p class="text-grey">{{ item.hobjects[0].subtitle }}</p>
                </vx-card> -->
      </div>
    </div>
  </div>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { videoPlayer }     from 'vue-video-player'
// import VxTimeline          from '@/components/timeline/VxTimeline.vue'
import 'video.js/dist/video-js.css'
import moduleDataList from '@/store/data-list/moduleDataList.js'

export default {
  components: {
    // VuePerfectScrollbar,
    // videoPlayer,
    // VxTimeline
  },
  data() {
    return {
      links: {},
      //gallery_description : {},
      gallery_photo: {},
      gallery_name: {},
      cards: {},

      playerOptions: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    }
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
  },
  mounted() {
    // // Close NavMenu
    // this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
    // // Update NavMenu Width
    // this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
  },
  created() {
    this.$vs.loading()

    if (!moduleDataList.isRegistered) {
      this.$store.registerModule('dataList', moduleDataList)
      moduleDataList.isRegistered = true
    }
    console.log(this.$route.path)

    var path = String(this.$route.path)
    var layerName = path.substring(path.lastIndexOf('/') + 1).toString()
    console.log(layerName)

    this.$store
      .dispatch('dataList/fetchLayerLinks', layerName)
      .then(response => {
        console.log('yoyo')
        console.log(response.data)
        this.gallery_name = response.data.name
        this.links = response.data.links
        this.gallery_photo = response.data.thumbnail

        response.data.links.forEach(element => {
          if (!element.hobjects[0].original_image) {
            try {
              element.hobjects[0].original_image = JSON.parse(element.hobjects[0].abilities).skin.source_url
            } catch (error) {
              console.log(error)
            }
          }
        })
        this.$vs.loading.close()
      })
      .catch(err => {
        this.$vs.loading.close()
        this.$router.push({ path: '/page-error-404' })

        console.error(err)
      })
  },
  methods: {
    OpenLink: function(pid) {
      window.open(`https://hoverlay.io/` + this.gallery_name + `/` + pid, '_blank')
    },
  },
}
</script>

<style lang="scss">
// .navbar-floating .router-content {
//         margin-top: 0rem;
// }
// .relative {
//    display:none;
// }
#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
</style>
